








































import { Users_users_items } from '@/features/app-customer/components/data-consumption-poll-panel/__generated__/Users';
import usersQuery from '@/features/app-customer/components/data-consumption-poll-panel/users.gql';
import { RestClient } from '@/features/core/rest-client/rest-client';
import { defineComponent } from '@vue/composition-api';
import { EMPTY, lastValueFrom } from 'rxjs';
import Spinner from '@/components/clickables/Spinner.vue';

interface AuditLogsViewProps {
  customerId: string;
}

type AuditLog = {
  notificationId: string;
  thirdPartyName: string;
  userName: string;
  customerName: string;
  auditLogType: string;
  timestamp: string;
};

interface ProcessedAuditLog extends AuditLog {
  username: string;
  customer: string;
  thirdParty: string;
}

interface AuditLogFilter {
  customerId?: string;
  userId?: string;
  type?: string; // This could be more strictly typed if needed
  sort?: 'asc' | 'desc';
  limit?: number;
  page?: number;
}

enum AuditLogType {
  Invalid = 'invalid',
  SentNotification = 'sent_notification',
  FailSendNotification = 'failed_to_send_notification',
  SentThirdPartyNotification = 'sent_third_party_notification',
  FailSendThirdPartyNotification = 'failed_to_send_third_party_notification',
  ReceivedNotificationUpdate = 'received_notification_update',
  SentIncident = 'sent_incident',
  FailSendIncident = 'failed_to_send_incident',
  SetupCustomerConfig = 'setup_customer_config',
  FailSetupCustomerConfig = 'failed_to_setup_customer_config',
  UpdatedCustomerConfig = 'updated_customer_config',
  FailUpdatedCustomerConfig = 'failed_to_update_customer_config',
  ReadCustomerConfig = 'read_customer_config',
  FailReadCustomerConfig = 'failed_to_read_customer_config',
  DeletedCustomerConfig = 'deleted_customer_config',
  FailDeleteCustomerConfig = 'failed_to_delete_customer_config',
}

type NotificationAuditLogsResponse = {
  logs: AuditLog[];
  count: number;
};

export default defineComponent<AuditLogsViewProps, Record<string, unknown>, any, any>({
  name: 'AuditLogsView',
  components: {
    Spinner,
  },
  inject: ['GENERIC_NOTIFICATION_API_REST_CLIENT'],
  props: {
    customerId: { type: String, required: true },
  },
  data() {
    return {
      auditLogs: [] as AuditLog[] | ProcessedAuditLog[],
      columns: [
        { label: 'Drittanbieter-API', field: 'thirdPartyName' },
        { label: 'Benutzer', field: 'userName' },
        { label: 'Kunden', field: 'customerName' },
        { label: 'Typ', field: 'auditLogType' },
        { label: 'Erstellt am', field: 'timestamp' },
      ],
      // pagination
      pageSize: 10,
      currentPage: 1,
      totalPages: null,
      client: undefined as RestClient | undefined,
      users: [],
      // Filtering
      filterAuditType: undefined,
      selectedUserId: undefined,
      loading: true,
    };
  },
  computed: {
    userOptions(): { label: string; value: string }[] {
      return this.users.map((user: Users_users_items) => {
        return {
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
        };
      });
    },
    AuditLogsTypes(): { label: string; value: string }[] {
      const options = Object.keys(AuditLogType).map((key) => ({
        label: key.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2'), // Add spaces between camelCase words
        value: AuditLogType[key as keyof typeof AuditLogType], // Get the enum value corresponding to the key
      }));

      return options;
    },
  },
  watch: {
    async currentPage(newPage: number) {
      this.currentPage = newPage;
      await this.loadAuditLogs();
    },
    async filterAuditType(newAuditType: string) {
      this.filterAuditType = newAuditType;
      await this.loadAuditLogs();
    },
    async selectedUserId(newUserId: string) {
      this.selectedUserId = newUserId;
      await this.loadAuditLogs();
    },
  },
  async created() {
    this.client = this.GENERIC_NOTIFICATION_API_REST_CLIENT as RestClient;
    await this.loadData();
  },
  methods: {
    async loadData(): Promise<void> {
      await this.loadUsers();
      await this.loadAuditLogs();
      this.loading = false;
    },
    async loadUsers(): Promise<void> {
      this.loading = true;

      const { data } = await this.$apollo.query({
        query: usersQuery,
        fetchPolicy: 'network-only',
        variables: {
          take: 5000,
        },
      });
      if (data?.users?.items) {
        this.users = data.users.items;
      }

      this.loading = false;
    },
    async loadAuditLogs() {
      this.loading = true;

      const loadedAuditData = await this.fetchAuditLogs({
        customerId: this.customerId,
        limit: this.pageSize,
        page: this.currentPage - 1 !== -1 ? this.currentPage - 1 : 0,
        type: this.filterAuditType,
        sort: 'desc',
        userId: this.selectedUserId,
      });
      if (loadedAuditData?.logs) {
        // Determine the page count
        this.totalPages = Math.ceil(loadedAuditData.count / this.pageSize);
        this.processAuditLogs(loadedAuditData.logs);
      } else {
        this.auditLogs = [];
        this.totalPages = null;
      }

      this.loading = false;
    },
    async fetchAuditLogs(filter: AuditLogFilter = {}): Promise<NotificationAuditLogsResponse | undefined | null> {
      try {
        const queryParams = new URLSearchParams();

        // Add filters to the query parameters if they exist
        if (filter.customerId) queryParams.append('customerId', filter.customerId);
        if (filter.userId) queryParams.append('userId', filter.userId);
        if (filter.type) queryParams.append('type', filter.type);
        if (filter.sort) queryParams.append('sort', filter.sort);
        if (filter.limit) queryParams.append('limit', String(filter.limit));
        if (filter.page) queryParams.append('page', String(filter.page));

        const queryString = queryParams.toString();

        return lastValueFrom(this.client?.get(`/api/v1/notifications/audit?${queryString}`) ?? EMPTY);
      } catch (error) {
        this.auditLogs = [];
        this.totalPages = null;
        // Handle error appropriately if needed
        console.error('Error fetching third party audit logs:', error);
      }
    },
    // Parse timestamp
    processAuditLogs(loadedAuditData: AuditLog[]): void {
      this.auditLogs = loadedAuditData.map((audit: AuditLog) => {
        return {
          ...audit,
          userName: audit.userName !== '' ? audit.userName : 'No user found',
          auditLogType: audit.auditLogType
            .replace(/_/g, ' ') // Replace underscores with spaces
            .replace(/([a-z])([A-Z])/g, '$1 $2') // Add spaces between camelCase words
            .split(' ') // Split the string into an array of words
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' '), // Join the words back into a single string,
          timestamp: new Date(audit.timestamp).toLocaleString(),
        };
      });
    },
    changeDataValue(property: string, value: string | null): void {
      this[property] = value === null ? undefined : value;
    },
  },
});
