





























import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Column } from '@/features/ui/table/model';
import { ConsumptionAccess } from '@/types/consumption-api/consumption-access.model';
import { VendorApi } from '@/types/consumption-api/vendor-api.model';
import { VendorApiType } from '@/types/iot-portal';
import { AddVendorOutPut } from '@/types/notification-api.model';
import { StringProp } from '@/util/prop-decorators';
import { EMPTY, Observable } from 'rxjs';
import { Component, Inject, Mixins } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { CONSUMPTION_API_REST_CLIENT, GENERIC_NOTIFICATION_API_REST_CLIENT } from '../../../../core/container';
import { RestClient } from '../../../../core/rest-client/rest-client';
import {
  AppCustomerVendorApisViewQuery,
  AppCustomerVendorApisViewQueryVariables,
} from './__generated__/AppCustomerVendorApisViewQuery';
import { AppCustomerVendorApisViewVendorApiFragment } from './__generated__/AppCustomerVendorApisViewVendorApiFragment';
import query from './view.gql';

@Component({
  apollo: {
    customer: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: VendorApisView): AppCustomerVendorApisViewQueryVariables {
        return { customerId: this.customerId, skip: this.skip, take: this.take };
      },
    },
  },
  data(this: VendorApisView) {
    this.getConsumptionApiSettings().subscribe((response) => {
      this.consumptionApis = response;
    });
    this.getNotificationApiSettings().subscribe((response) => {
      this.notificationApis = response;
    });
    return { customer: undefined, consumptionApis: undefined, notificationApis: undefined };
  },
})
export default class VendorApisView extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly customerId!: string;

  private readonly customer?: AppCustomerVendorApisViewQuery['customer'];

  private consumptionApis?: ConsumptionAccess[];

  private notificationApis?: AddVendorOutPut[];

  private readonly columns: Column[] = [
    { name: 'type', label: 'Datenanbieter' },
    { name: 'label', label: 'Name' },
    { name: 'action', label: '', align: 'right' },
  ];

  @Inject(CONSUMPTION_API_REST_CLIENT)
  private consumptionClient?: RestClient;

  @Inject(GENERIC_NOTIFICATION_API_REST_CLIENT)
  private notificationClient?: RestClient;

  private get apis(): VendorApi[] {
    const portalVendorApis = this.customer?.first.vendorApis.items ?? [];
    const consumptionApis = this.consumptionApiList;
    const notificationApis = this.notificationApiList;

    // only display additional consumption and notification data on the first page.
    return this.currentPage === 1
      ? [...portalVendorApis, ...consumptionApis, ...notificationApis]
      : [...portalVendorApis];
  }

  private get apisLoading(): boolean {
    return this.$apollo.queries.customer.loading || this.consumptionApis == null || this.notificationApis == null;
  }

  private get consumptionApiList(): VendorApi[] {
    if (this.consumptionApis) {
      return this.consumptionApis.map((api) => ({
        __typename: 'IvmConsumptionApi',
        id: api.id,
        label: api.name,
        type: VendorApiType.IvmConsumptionApi,
      }));
    }

    return [];
  }

  private get notificationApiList(): VendorApi[] {
    if (this.notificationApis) {
      return this.notificationApis.map((api) => ({
        __typename: 'IvmNotificationApi',
        id: api.id,
        label: api.label,
        type: VendorApiType.IvmNotificationApi,
      }));
    }

    return [];
  }

  private vendorApiTypeString(row: VendorApi): string {
    if (row?.__typename === 'ConsumptionDataExchange') {
      return `ConsumptionDataExchange${row.consumptionDataExchangeType}`;
    } else if (row?.__typename === 'LegacyVendorApi') {
      return row?.type;
    }
    return row?.__typename;
  }

  protected get count(): number {
    return this.customer?.first.vendorApis.count ?? 0;
  }

  private routeToVendorApi({ __typename, id }: AppCustomerVendorApisViewVendorApiFragment): RawLocation {
    return { name: `AppCustomer/VendorApis/${__typename}`, params: { vendorApiId: id } };
  }

  private getConsumptionApiSettings(): Observable<ConsumptionAccess[]> {
    const response =
      this.consumptionClient?.get<ConsumptionAccess[]>(
        `/api/v1/consumption/consumption-access-list?customerId=${this.customerId}`,
      ) ?? EMPTY;

    return response;
  }

  private getNotificationApiSettings(): Observable<AddVendorOutPut[]> {
    const response =
      this.notificationClient?.get<VendorApi[]>(`/api/v1/notifications?customerId=${this.customerId}`) ?? EMPTY;

    return response;
  }
}
