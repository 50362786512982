var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-panel',[_c('ui-flex',{attrs:{"items":"center"}},[_c('ui-cell',{attrs:{"min":150,"max":280}},[_c('ui-input-select',{attrs:{"data-element-id":'audit-type-select',"value":_vm.filterAuditType,"options":_vm.AuditLogsTypes,"placeholder":"Drittanbieter","reduce":function (ref) {
	var value = ref.value;

	return value;
}},on:{"update":function($event){return _vm.changeDataValue('filterAuditType', $event)}}})],1),_c('ui-cell',{attrs:{"min":150,"max":280}},[_c('ui-input-select',{attrs:{"data-element-id":'user-type-select',"value":_vm.selectedUserId,"options":_vm.userOptions,"placeholder":"Benutzer","reduce":function (ref) {
	var value = ref.value;

	return value;
}},on:{"update":function($event){return _vm.changeDataValue('selectedUserId', $event)}}})],1)],1),_c('ui-flex',[_c('ui-cell',{attrs:{"grow":1}},[(_vm.loading)?_c('Spinner',{attrs:{"dark":true}}):_vm._e(),_c('ui-table-skeleton',{attrs:{"ready":!_vm.loading,"column-count":4,"line-count":2,"header-line-count":2}}),(!_vm.loading)?_c('div',[(_vm.auditLogs.length > 0)?_c('div',[(!_vm.loading)?_c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.auditLogs,"align":"left","striped":""}}):_vm._e(),_c('ui-pagination',{attrs:{"total-pages":_vm.totalPages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_c('ui-form-message',{staticClass:"no-data-message",attrs:{"warning":""}},[_vm._v("Keine Audit-Protokolle gefunden")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }